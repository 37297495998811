// eslint-disable-next-line no-use-before-define
import React from 'react'
import { Layout } from '@components/common'
import type { GetStaticPropsContext } from 'next'
import { getConfig } from '@framework/api'
import getSiteInfo from '@framework/common/get-site-info'
import Head from 'next/head'
import { GTM_ID, track404 } from '@utils/gtm'
import { revalidatePeriod } from '@utils/server-side-config'
import { getPageData } from '@utils/contentful/get-page-data'
import { Link } from '@noissue-ui-kit/oldBranding/link'
import { Builder, BuilderComponent } from '@builder.io/react'

export async function getStaticProps({
  preview,
  locale,
}: GetStaticPropsContext<{ slug: string }>) {
  const config = getConfig({ locale })
  const { categories } = await getSiteInfo({ config, preview })
  const pageData = await getPageData({ categories, preview })

  return {
    props: { ...pageData },
    revalidate: revalidatePeriod(), // Need it for refreshing mega menu
  }
}

class PageNotFound extends React.Component {
  componentDidMount() {
    track404({ url: window.location.href })
  }

  render() {
    return (
      <>
        <div className="not-found flex flex-col items-center justify-center h-[50vh] p-12">
          <h1 className="mb-16 text-5xl font-bold md:mb-24 md:text-7xl text-core-grey-90">
            404 - Page Not Found
          </h1>

          <Link
            href="/category/all/"
            size="m"
            iconName="HiArrowRight"
            iconSide="right"
          >
            Return to catalog
          </Link>
        </div>
      </>
    )
  }
}

export default function FourOhFour() {
  if (Builder.isPreviewing || Builder.isEditing) {
    return <BuilderComponent model="page" />
  }

  return (
    <>
      <Head>
        {/* Google Tag Manager - Global base code */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
        <link href="https://use.typekit.net/vao4ikf.css" rel="stylesheet" />
      </Head>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <PageNotFound />
    </>
  )
}

FourOhFour.Layout = Layout
